import "./App.css";
import { useState } from "react";
import Search from "./components/Search";
import ItemList from "./components/ItemList";

function App() {
  const [items, setItems] = useState([]);
  const [meme, setMeme] = useState(false);

  return (
    <div className="App p-5">
      <div className="bg-orange-500 max-w-5xl mx-auto p-2 underline rounded-t-md shadow-md">
        <img
          src={meme ? "../img/image.png" : "../img/jetlogo.svg"}
          alt="JET Logo"
          className="w-16 md:w-20 lg:w-24 mx-auto"
        />
      </div>

      <Search setItems={setItems} setMeme={setMeme} />

      {items && items.length > 0 && <ItemList items={items} />}
      <div className="my-5 font-medium text-sm text-slate-600">
        Made with 🧡 by Reece Devonport
      </div>
    </div>
  );
}

export default App;
