import React from "react";
import { BsFillExclamationCircleFill } from "react-icons/bs";

function Item({ item }) {
  const truncate = (str) => {
    return str.length > 150 ? str.substring(0, 149) + "..." : str;
  };

  const gbp = new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
  });

  return (
    <div className="bg-slate-200 rounded-md px-4 py-8 shadow-md flex items-center justify-center">
      <div>
        <h1 className="text-sm font-bold text-slate-700">{item.Name}</h1>
        <h3 className="text-xs text-slate-500 mb-3 italic">
          {truncate(item.Description)}
        </h3>
        {item.Variations.length > 1 ? (
          item.Variations.map((variant) => (
            <>
              <h2 className="text-base text-slate-700">
                {variant.Name} -{" "}
                <span className="font-bold text-orange-500">
                  {gbp.format(variant.BasePrice)}
                </span>
              </h2>
            </>
          ))
        ) : (
          <>
            <h2 className="text-base font-bold text-orange-500 flex items-center justify-center">
              {gbp.format(item.Variations[0].BasePrice)}
              {item.Variations[0].BasePrice < 1 && (
                <BsFillExclamationCircleFill
                  className="text-center text-red-500 inline ml-2 cursor-pointer"
                  title="Low cost item, please check for duplicate listing."
                />
              )}
            </h2>
          </>
        )}
      </div>
    </div>
  );
}

export default Item;
